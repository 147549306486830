"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('./../scss/sites/mattsson/mattsson.scss');
var index_1 = require("./../js/index");
var mattsson_1 = require("./../js/Sites/mattsson");
// Initialize scripts that are used in all websites
index_1.Init();
// Initialize Mattsson specific scripts
mattsson_1.InitMattsson();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
index_1.InitReact();
