"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var ProductBox_1 = require("./Elements/ProductBox");
var Store_1 = require("./Store");
var ContentArea_1 = require("./Elements/ContentArea");
var ProductList = /** @class */ (function (_super) {
    __extends(ProductList, _super);
    function ProductList(props) {
        var _this = _super.call(this, props) || this;
        Store_1.default.loadProducts();
        Store_1.default.updateNavigationColor(Store_1.default.containerId);
        return _this;
    }
    ProductList.prototype.componentDidMount = function () {
        document.title = Store_1.default.currentPageName + " | Mattsson";
    };
    ProductList.prototype.render = function () {
        var backgroundImageUrl = Store_1.default.BackgroundImageUrl;
        var hasBgImage = backgroundImageUrl && backgroundImageUrl.length > 0;
        var dimFilter = Store_1.default.DimFilter;
        var headerText = Store_1.default.Translations.headerText;
        var ingressText = Store_1.default.Translations.ingressText;
        var bgStyle = React.createElement("style", null);
        var filterStyle = {};
        if (hasBgImage) {
            filterStyle.backgroundColor = 'black';
            filterStyle.opacity = 0.25;
            bgStyle = React.createElement("style", { dangerouslySetInnerHTML: {
                    __html: "@media all and (max-width: 600px) {\n                   .product-listing-background-image {\n                       background-image: url('" + backgroundImageUrl + "?width=800&CropMode=percentage&crop=18,0,18,0');\n                       background-size: cover;\n                       background-repeat: no-repeat;\n                   }\n                 }\n                 @media all and (min-width: 601px) and (max-width: 1024px) {\n                    .product-listing-background-image {\n                        background: url('" + backgroundImageUrl + "?width=1024');\n                        background-size: cover;\n                        background-repeat: no-repeat;\n                    }\n                }\n                @media all and (min-width: 1025px) {\n                    .product-listing-background-image {\n                        background: url('" + backgroundImageUrl + "?width=1200');\n                        background-size: cover;\n                        background-repeat: no-repeat;\n                    }\n               }"
                } });
            window.addFullWidth();
        }
        else {
            window.removeFullWidth(true);
        }
        return (React.createElement(React.Fragment, null,
            hasBgImage && bgStyle,
            hasBgImage &&
                React.createElement("div", { className: "row u-pt-10 _md-u-pt-17 u-mb-3 u-relative product-listing-background-image" },
                    dimFilter && React.createElement("div", { className: "s-bg-cover background-image", style: filterStyle }),
                    React.createElement("div", { className: "col-lg-12 col-sm-12 col-xs-12 u-z-1" },
                        React.createElement("div", { className: "o-container u-full-width" },
                            React.createElement("h1", { className: "u-tc s-hero s-themecolor-1 o-product-listing-heading" }, headerText),
                            React.createElement("div", { className: "o-ingress row" },
                                React.createElement("div", { className: "col-md-6 col-sm-9 col-xs-12 u-tc s-themecolor-1" }, ingressText))))),
            !hasBgImage && React.createElement("h1", { className: "u-tc s-hero s-themecolor-1 o-product-listing-heading" }, headerText),
            React.createElement("div", { className: "o-container" },
                React.createElement("div", { className: "o-product-listing-wrapper" },
                    Store_1.default.loading &&
                        React.createElement("div", { className: "ui active inverted dimmer" },
                            React.createElement("div", { className: "ui text loader" }, Store_1.default.Translations.loadingText)),
                    React.createElement("div", { className: "row u-pb-12" }, Store_1.default.Products && Store_1.default.Products.map(function (product, index) {
                        return (React.createElement(ProductBox_1.default, { key: product.id, product: product, columnSize_medium: 4 }));
                    })))),
            React.createElement(ContentArea_1.default, { content: Store_1.default.containerId, language: Store_1.default.currentLanguage, area: "ProductContentArea" })));
    };
    ProductList = __decorate([
        mobx_react_1.observer
    ], ProductList);
    return ProductList;
}(React.Component));
exports.default = ProductList;
