"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require('select2');
function InitMattsson() {
    window.addFullWidth = function () {
        if (!$(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").addClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").addClass("has-bg-image");
        }
    };
    window.removeFullWidth = function () {
        if ($(".content-wrapper").hasClass("has-bg-image")) {
            $(".o-navigation-wrapper").removeClass("u-z-999 u-absolute u-full-width");
            $(".content-wrapper").removeClass("has-bg-image");
        }
    };
    $(document).ready(function () {
        // Add space before Image content block if it's the first element of the content area
        $('[data-block-type]').each(function (i, block) {
            if (i === 0 && $(block).data('block-type') === 'imagecontentblock') {
                $(block).addClass("_md-u-mt-6");
            }
        });
        //Initialize select2 for Contact form
        $('.Form__Element.FormSelection select').hide();
        $('.Form__Element.FormSelection select').select2({
            minimumResultsForSearch: Infinity,
            width: 'resolve',
            dropdownParent: $('.Form__MainBody')
        });
    });
}
exports.InitMattsson = InitMattsson;
